"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.components = void 0;
exports.components = {
    accountFaq: {
        subheading: "FAQs",
        heading: "Your Questions Answered",
        plantedTrees: "We've planted 43,920 trees across three UK forests.",
        carbonNeutral: "We're 100% carbon neutral.",
    },
    loginForm: {
        submit: "Login",
    },
    needHelp: {
        title: "Need help or have a problem?",
        subtitle: "Begin a live chat with one of our helpful teams.",
    },
    drawerView: {
        appVersion: "App Version",
    },
    expiredBasket: {
        heading: "Basket expired",
        message: "Your order was started some time ago and the basket has now expired, please click the button below to start the process again.",
        button: "Start again",
    },
    authError: {
        heading: "Auth error",
        messageWeb: "You may want to try refresh the page or sign out/sign in",
        messageNative: "You may want to sign out/sign in.",
        refreshButton: "Refresh",
        signOutWarning: "Warning: signing out will cause your basket to empty",
    },
    dropdownLookup: {
        findAddress: "Find address",
    },
    orderTrain: {
        accepted: "Order is placed and confirmation with us",
        onTheWay: "You'll get a notification when we are on our way to you",
        arrived: "Our drivers arrive at your designated address",
        collected: "Your waste has been collected from you",
        tipped: "Your waste has arrived at a waste transfer station for processing",
        completed: "Job completed, Waste Transfer Note available",
    },
    accountLayout: {
        logoutModal: {
            heading: "Are you sure you want to logout?",
            yesLogout: "Yes, logout",
        },
    },
    jobsView: {
        noPreviousOrders: "You do not have any previous orders",
    },
    guestDashboard: {
        treesPlanted: "We've planted 43,920 trees across three UK forests.",
        carbonNeutral: "We're 100%\ncarbon neutral.",
    },
    postcodePricing: {
        skipsPrivateLand: "Currently, all skips must be placed on private land.",
        skipsPrivateLandWhy: "if you need your skip placing on public land.",
    },
    postcodeResultMessage: {
        unavailablePostcode: "No products found for",
        showPostcodePrices: "Showing prices for",
        enterPostcode: "Enter postcode above to get accurate prices",
        invalidPostcode: "This postcode does not exist, please enter a valid postcode",
    },
    viewJobs: {
        yourPreviousJobs: "Your previous jobs",
        yourJobs: "Your jobs",
    },
    loginOrRegister: {
        title: "Login or create",
        subtitle: "your account",
        useClearabeeCredentials: "You can use your Clearabee website login credentials.",
        successTitle: "Account",
        successSubtitle: "Created Successfully",
        successEmail: "A verification email has been sent to your email address. Please follow the link in the email.",
    },
    loginLayout: {
        benefits: {
            first: "Manage waste removal anytime, anywhere.",
            second: "A range of services tailored to your rubbish removal requirements.",
            third: "Affordable and convenient solutions.",
        },
        loginOrSignUpHeader: "Login or Sign up to unlock special features",
    },
    tooltip: {
        heavyWeight: "Our collections come with a 100kg weight allowance per 0.76m³ / 1yd³ up to 1 tonne per load.",
    },
    bulkAddDetailsPanel: {
        yourItems: "Your Items",
        noMatch: "No products match your search",
    },
    calloutChargeModal: {
        title: "Call-out charge",
        content: "This service includes a call-out charge. When sending one of our collection vans we always include a call-out charge of {{ price }}.",
    },
};
