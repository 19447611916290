import React, { useState } from "react";
import { Button } from "../Button/Button";
import { Heading } from "../Heading/Heading";
import { Icon } from "../Icon/Icon";
import { Message } from "../Message/Message";
import { Panel } from "../Panel/Panel";
import { styles } from "./DefaultErrorComponent.styles";

interface DefaultErrorBoundaryProps {
  children?: string;
  message?: string;
  basicMessage?: string;
  onRetry?: () => void;
}
export const DefaultErrorComponent = ({
  children,
  basicMessage = "An error occured",
  message,
  onRetry,
}: DefaultErrorBoundaryProps): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  return (
    <Panel data-testid="DefaultErrorBoundary" styles={styles.panel}>
      <Heading styles={styles.heading} fontSize="xlarge" level={1}>
        {basicMessage}
      </Heading>
      <div style={styles.detailsContainer}>
        <div style={styles.toggleContainer}>
          <div
            style={styles.toggleContainerLeft}
            onClick={() => setIsOpen(!isOpen)}
          >
            <Icon.DoubleChevron
              size="small"
              styles={[
                {
                  transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
                },
                styles.chevron,
              ]}
            />
            Details
          </div>
          {isOpen && (
            <div style={styles.clipboardWrapper}>
              {isCopied && (
                <Message type="success" small color="light">
                  Copied
                </Message>
              )}
              <Icon.Clipboard
                size="small"
                styles={styles.clipboard}
                onClick={() => {
                  navigator.clipboard.writeText(`${message}`);
                  setIsCopied(true);
                }}
              />
            </div>
          )}
        </div>
        {isOpen && (
          <Panel styles={styles.errorStack}>{message || children}</Panel>
        )}
      </div>
      {onRetry && (
        <Button
          styles={styles.buttonText}
          color="warning"
          size="small"
          onClick={() => {
            onRetry();
          }}
        >
          <Icon.Undo size="small" styles={styles.retryIcon} />
          Retry
        </Button>
      )}
    </Panel>
  );
};
