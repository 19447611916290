"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.auth = void 0;
exports.auth = {
    form: {
        label: {
            emailAddress: "$t(common:emailAddress)",
            password: "$t(common:password)",
            passwordConfirmation: "Confirm Password",
            newPassword: "New Password",
            oldPassword: "Temporary Password",
        },
        placeholder: {
            emailAddress: "$t(common:emailAddress)",
            password: "$t(common:password)",
            passwordConfirmation: "$t(auth:form.label.passwordConfirmation)",
            newPassword: "Your new password",
            oldPassword: "Your temporary password",
        },
        errors: {
            invalidEmail: "This email is invalid",
            requiredField: "This field is required",
            matchField: "Passwords must match",
            invalidDate: "This is an invalid date format",
        },
        buttons: {
            login: "$t(common:login)",
            forgot: "$t(auth:title.forgot)",
            save: "Save Password",
        },
        links: {
            forgotPassword: "Forgot password?",
            back: "$t(common:back)",
        },
    },
    title: {
        login: "Hey!",
        forgot: "Reset Password",
        reset: "New Password",
        welcome: "Welcome!",
    },
    description: {
        login: "Sign into your account.",
        forgot: "Enter your email address and click the ‘reset password’ button and we’ll send you a link to create a new password..",
        reset: "Create a new password. Your password must be at least 8 characters in length.",
        welcome: "Set a strong password for your new account. Minimum 7 characters: 1 special character, number and uppercase/lowercase letter.",
    },
    strapline: {
        start: "We are the",
        middle: "U.K's largest Man & Van",
        end: "clearance company.",
    },
    modal: {
        headings: {
            verify: "Password verification code sent!",
        },
        buttons: {
            signIn: "Sign in",
        },
    },
};
