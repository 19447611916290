"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CatalogueProvider = exports.CatalogueContext = void 0;
var react_1 = __importStar(require("react"));
exports.CatalogueContext = react_1.createContext({});
var CatalogueProvider = function (_a) {
    var children = _a.children, initialStore = _a.initialStore;
    var _b = react_1.useState(initialStore), store = _b[0], setStore = _b[1];
    var setCatalogue = function (key, data) {
        var _a;
        setStore(__assign(__assign({}, store), (_a = {}, _a[key] = data, _a)));
    };
    var getCatalogue = function (key) {
        return store[key];
    };
    return (react_1.default.createElement(exports.CatalogueContext.Provider, { value: {
            defaultCatalogue: initialStore.defaultCatalogue,
            setCatalogue: setCatalogue,
            getCatalogue: getCatalogue,
        } }, typeof children === "function"
        ? children({
            defaultCatalogue: initialStore.defaultCatalogue,
            setCatalogue: setCatalogue,
            getCatalogue: getCatalogue,
        })
        : children));
};
exports.CatalogueProvider = CatalogueProvider;
