"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.theme = void 0;
var themeValues = {
    container: {
        width: "1472px",
    },
    stack: {
        top: 200,
        modal: 150,
        header: 140,
        inputSelect: 110,
        inputFile: 100,
        inputDate: 50,
        overlay: 10,
    },
    screens: {
        xsmall: "360px",
        small: "576px",
        medium: "768px",
        large: "1024px",
        xlarge: "1280px",
        xlarge2: "1400px",
        xlarge3: "1500px",
        xlarge4: "2000px",
    },
    colors: {
        dark: { base: "#000000" },
        light: { base: "#FFFFFF" },
        focus: { base: "#00C1FF" },
        brand: {
            base: "#8645EA",
            lightest: "#E8DCFB",
            lighter: "#D2B9F7",
            light: "#9E6AEE",
            dark: "#6B37BB",
            darker: "#432375",
            darkest: "#281546",
        },
        accent: {
            base: "#56F7B2",
            lightest: "#DFFDF1",
            lighter: "#C0FCE6",
            light: "#81F9C6",
            dark: "#45C68E",
            darker: "#2B7C59",
            darkest: "#1A4A35",
        },
        positive: {
            base: "#00D42A",
            lightest: "#B3F2BF",
            lighter: "#80EA95",
            light: "#33DD55",
            dark: "#00AA22",
            darker: "#006A15",
            darkest: "#00400D",
        },
        negative: {
            base: "#FF006E",
            lightest: "#FFCFE4",
            lighter: "#FF9FC9",
            light: "#FF4093",
            dark: "#CC0058",
            darker: "#800037",
            darkest: "#4C0021",
        },
        warning: {
            base: "#FFBF0A",
            lightest: "#FFF3D1",
            lighter: "#FFE7A3",
            light: "#FFCF48",
            dark: "#CC9908",
            darker: "#806005",
            darkest: "#4C3903",
        },
        usp: {
            base: "#FB5607",
            lightest: "#FECCB5",
            lighter: "#FDAB83",
            light: "#FC7839",
            dark: "#C94506",
            darker: "#7E2B04",
            darkest: "#4B1A02",
        },
        greyscale: {
            base: "#1D1D1B",
            lightest: "#EAEAEA",
            lighter: "#AAAAA9",
            light: "#565654",
            dark: "#171716",
            darker: "#0F0F0E",
            darkest: "#090908",
        },
    },
    spacing: {
        xsmall2: "4px",
        xsmall: "8px",
        small: "16px",
        medium: "24px",
        large: "32px",
        xlarge: "40px",
        xlarge2: "48px",
        xlarge3: "64px",
        xlarge4: "96px",
        xlarge5: "120px",
    },
    fontDefaults: {
        xlarge5: {
            fontSize: "120px",
            lineHeight: "136px",
        },
        xlarge4: {
            fontSize: "60px",
            lineHeight: "68px",
        },
        xlarge3: {
            fontSize: "30px",
            lineHeight: "36px",
        },
        xlarge2: {
            fontSize: "28px",
            lineHeight: "34px",
        },
        xlarge: {
            fontSize: "22px",
            lineHeight: "28px",
        },
        large: {
            fontSize: "20px",
            lineHeight: "28px",
        },
        base: {
            fontSize: "18px",
            lineHeight: "22px",
        },
        small: {
            fontSize: "16px",
            lineHeight: "20px",
        },
        xsmall: {
            fontSize: "14px",
            lineHeight: "18px",
        },
        xsmall2: {
            fontSize: "10px",
            lineHeight: "12px",
        },
    },
};
exports.theme = themeValues;
