"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deprecated = void 0;
exports.deprecated = {
    global: {
        manVan: "Man & Van",
        skipHire: "Skip Hire",
        skipBags: "Skip Bags",
        binCollection: "Bin Collection",
        sofaRemoval: "Sofa Removal",
    },
    common: {
        navigation: {
            home: "Home",
            about: "About",
            services: "Services",
            wasteCalculator: "Waste calculator",
            beeLoyal: "Beeloyal",
            login: "Login",
            bookNow: "Book now",
            getQuote: "Get a Quote",
            account: "Account",
            manVan: "Man & Van",
            skipHire: "Skip Hire",
            skipBags: "Skip Bags",
            binCollection: "Bin Collection",
            sofaRemoval: "Sofa Removal",
            close: "Close",
        },
    },
    auth: {
        form: {
            errors: {
                invalidEmail: "This email is invalid",
                requiredField: "This field is required",
                matchField: "Passwords must match",
                invalidPhone: "This phone number is invalid",
                invalidPostcode: "This postcode is invalid",
                invalidNumber: "Must be a number",
                invalidPositiveNumber: "Must be a positive number",
                passwordRequirements: "6 characters minimum length",
            },
        },
    },
    home: {},
    news: {
        headings: {
            blog: "Blog",
        },
        buttons: {
            back: "Back to News",
        },
    },
    login: {
        headings: {
            login: "Login",
            register: "Create account",
            passwordReset: "Reset password",
        },
        labels: {
            sendMeInfo: "Send me info about discounts, new services and major updates.",
        },
    },
    footer: {
        heading: "Removing Rubbish.",
        subheading: "It's what we do",
        linkHeadings: {
            services: "Services",
            locations: "Locations",
        },
        links: {
            rubbishRemoval: "Rubbish Removal",
            skipHire: "Skip Hire",
            binCollection: "Bin Collection",
            sofaRemoval: "Sofa Removal",
            businessWaste: "Business Waste",
            skipBags: "Skip Bags",
            nationwide: "Nationwide",
            london: "London",
            birmingham: "Birmingham",
            bristol: "Bristol",
            leeds: "Leeds",
            about: "About Us",
            news: "News",
            reviews: "Reviews",
            contact: "Contact Us",
            terms: "Terms & Conditions",
            privacy: "Privacy Policy",
            arrivalAndDelivery: "Arrival & Delivery",
        },
        noticeOne: "© Clearabee 2020. All rights reserved. Clearabee is a trading name of Clearabee Limited.",
        noticeTwo: "Company Reg: 07938514 / VAT Reg: 141 0827 45. Waste Carrier License: CBDU61392",
    },
};
