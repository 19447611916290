import { CSSProperties } from "react";
import { BreakpointStyles, Screens } from "./types";
import { theme } from "../theme";

export const createResponsiveStyles = ({
  base,
  ...breakpointStyles
}: BreakpointStyles) => {
  return {
    ...base,
    ...Object.entries(breakpointStyles).reduce(
      (final, [screen, styles]: [string, CSSProperties]) => {
        if (styles) {
          final[`@media (min-width: ${theme.screens[screen as Screens]})`] = {
            ...styles,
          };
        }
        return final;
      },
      {} as { [key: string]: CSSProperties },
    ),
  };
};
