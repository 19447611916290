import React, { useContext, createContext, useState } from "react";
import { css } from "@emotion/react";
import { UserStylesProps } from "../../utils";
import { AllowedColorKeys, styles } from "./Accordion.styles";
import { SunSvg } from "../../Core/Sun/Sun";
import { sunSvgColors } from "../../Core/Sun/Sun.styles";
import { Icon } from "../../Core/Icon/Icon";
import { Heading } from "../../Core/Heading/Heading";

export interface AccordionSharedProps {
  color?: AllowedColorKeys;
  initial?: string;
  singular?: boolean;
}

export interface AccordionContext extends AccordionSharedProps {
  openItems: string[];
  setOpenItems: React.Dispatch<React.SetStateAction<string[]>>;
  open: (index: string) => void;
  close: (index: string) => void;
  toggle: (index: string) => void;
  reset: () => void;
}

export const AccordionContext = createContext<Partial<AccordionContext>>({});

export type AccordionProps = UserStylesProps<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> &
  AccordionSharedProps;

export interface AccordionItemProps
  extends UserStylesProps<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
  > {
  label: React.ReactNode;
  index?: string;
}

const AccordionComponent = ({
  initial,
  color = "brand",
  singular = false,
  children,
  styles: userStyles,
  ...rest
}: AccordionProps): React.ReactElement => {
  const [openItems, setOpenItems] = useState<AccordionContext["openItems"]>(
    initial ? [initial] : [],
  );

  const open: AccordionContext["open"] = (index) => {
    setOpenItems((currentOpen) => {
      if (!currentOpen.includes(index)) {
        return [...(!singular ? currentOpen : []), index];
      }

      return currentOpen;
    });
  };

  const close: AccordionContext["close"] = (index) => {
    setOpenItems((currentOpen) => currentOpen.filter((i) => i !== index));
  };

  const toggle: AccordionContext["toggle"] = (index) => {
    setOpenItems((currentOpen) => {
      if (!currentOpen.includes(index)) {
        return [...(!singular ? currentOpen : []), index];
      }

      return currentOpen.filter((i) => i !== index);
    });
  };

  const reset: AccordionContext["reset"] = () =>
    setOpenItems(initial ? [initial] : []);

  return (
    <AccordionContext.Provider
      value={{
        initial,
        color,
        singular,
        openItems,
        setOpenItems,
        open,
        close,
        toggle,
        reset,
      }}
    >
      <div css={css([styles.defaults, styles[color], userStyles])} {...rest}>
        {children}
      </div>
    </AccordionContext.Provider>
  );
};

const AccordionItem = ({
  label,
  children,
  index,
  ...rest
}: AccordionItemProps): React.ReactElement => {
  const { openItems, toggle, color } = useContext(AccordionContext);

  // Allows the item to be used on it's own outside of an accordion context
  const [internalOpen, setInternalOpen] = useState(false);
  const isOpen = openItems ? index && openItems.includes(index) : internalOpen;

  const iconColor =
    color === "brand" || color === "negative" || color === "usp"
      ? "light"
      : "dark";

  return (
    <div {...rest} css={css(styles.item)}>
      <div className="flex flex-col">
        <div
          css={css(styles.headingContainer)}
          role="switch"
          onClick={() =>
            toggle && index ? toggle(index) : setInternalOpen(!internalOpen)
          }
        >
          <Heading level={6} styles={styles.heading}>
            {label}
          </Heading>
          <div css={css(styles.toggle)}>
            <SunSvg
              colors={sunSvgColors[color || "brand"]}
              width={60}
              height={60}
              hideStroke={true}
            />
            <div css={css(styles.icon)}>
              {index && openItems?.includes(index) ? (
                <Icon.Minus size="medium" color={iconColor} />
              ) : (
                <Icon.Plus size="medium" color={iconColor} />
              )}
            </div>
          </div>
        </div>

        <div
          data-testid="accordion-content"
          role="document"
          css={css(styles.content, isOpen && styles.contentVisible)}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

AccordionComponent.Item = AccordionItem;

export const Accordion = AccordionComponent;
