"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getItemDescendants = void 0;
var getItemDescendants = function (categorySku, allItems, levels, totalChildren) {
    if (levels === void 0) { levels = "all"; }
    if (totalChildren === void 0) { totalChildren = []; }
    var children = allItems.filter(function (_a) {
        var parentSku = _a.parentSku;
        return parentSku === categorySku;
    });
    totalChildren.push.apply(totalChildren, children);
    if (levels === "all" || levels - 1 !== 0)
        for (var _i = 0, children_1 = children; _i < children_1.length; _i++) {
            var child = children_1[_i];
            exports.getItemDescendants(child.sku, allItems, typeof levels === "number" ? levels - 1 : levels, totalChildren);
        }
    return totalChildren;
};
exports.getItemDescendants = getItemDescendants;
