import React, { useState, useRef, useEffect } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { css } from "@emotion/react";
import DayPicker, { DayModifiers, DayPickerProps } from "react-day-picker";
import { UserStylesProps, CustomInputProps } from "../../../../utils";
import { styles } from "./InputDate.styles";
import { InputText } from "../InputText/InputText";
import { Icon } from "../../../Icon/Icon";
import {
  BaseInputDate,
  BaseProps,
  convertToDate,
  defaultDateFormat,
  formatDate,
  setNativeValue,
} from "./BaseInputDate";

dayjs.extend(customParseFormat);

export interface InputDateProps
  extends CustomInputProps,
    UserStylesProps<
      React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >
    >,
    Pick<DayPickerProps, "disabledDays" | "month">,
    BaseProps {
  initialValue?: string;
  dateFormat?: string;
  multiMonth?: boolean;
  collapsable?: boolean;
  explicitValue?: string;
}

export const InputDate = ({
  styles: userStyles,
  dateFormat = defaultDateFormat,
  disabledDays,
  initialValue = "",
  multiMonth = false,
  collapsable = false,
  explicitValue,
  month,
  firstDay,
  ...rest
}: InputDateProps): React.ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  const calendarRef = useRef<DayPicker>(null);
  const [value, setValue] = useState(initialValue);
  const [showPicker, setShowPicker] = useState(!collapsable);

  const handleDayChange = (day: Date, modifiers: DayModifiers) => {
    if (!inputRef.current || modifiers["UI-DayPicker-Day--disabled"]) {
      return;
    }

    setNativeValue(inputRef.current, formatDate(day, dateFormat));
    inputRef.current.dispatchEvent(new Event("input", { bubbles: true }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    rest.onChange?.(event);
  };
  const handleReset = () => setValue("");

  /**
   * Reset input value.
   */
  useEffect(() => {
    if (value && rest.value === "") {
      handleReset();
    }
  }, [rest.value]);

  return (
    <div
      data-testid="date-input-component"
      css={css(collapsable && styles.container, userStyles)}
    >
      {collapsable && (
        <>
          <Icon.Chevron
            color="brand"
            size="small"
            styles={css(
              styles.icon,
              showPicker && styles.up,
              rest.error && styles.dropdownError,
              rest.disabled && styles.dropdownDisabled,
            )}
          />
          <InputText
            {...rest}
            value={explicitValue ? explicitValue : value}
            ref={inputRef}
            autoComplete="off"
            onChange={handleChange}
            onClick={(event) => {
              setShowPicker(!showPicker);
              rest.onClick?.(event);
            }}
            data-testid="date-input-field"
          />
        </>
      )}
      {!collapsable && (
        <InputText
          ref={inputRef}
          onChange={handleChange}
          style={{ display: "none" }}
        />
      )}

      <div
        css={[
          styles.dayPicker,
          collapsable && !showPicker && styles.hideCalendar,
          !collapsable && styles.notCollapsable,
        ]}
      >
        <BaseInputDate
          multiMonth={multiMonth}
          dateRef={calendarRef}
          inputRef={inputRef}
          selectedDays={[convertToDate(value, dateFormat)]}
          modifiers={{
            start: convertToDate(value, dateFormat),
            end: convertToDate(value, dateFormat),
          }}
          month={month ?? convertToDate(value, dateFormat)}
          disabledDays={disabledDays}
          onDayClick={(day, modifiers) => {
            handleDayChange(day, modifiers);
            setShowPicker(false);
          }}
          onOutsideClick={() => setShowPicker(false)}
          onReset={handleReset}
          firstDay={firstDay}
        />
      </div>
    </div>
  );
};
