"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.users = void 0;
exports.users = {
    common: {
        title: "Users",
        addUser: "Add user",
    },
    user: {
        form: {
            labels: {
                firstName: "First Name",
                lastName: "Last Name",
                emailAddress: "Email Address",
                phoneNumber: "Phone Number",
                address: "Address",
                company: "Company",
                role: "Role",
                backToUsers: "Back to users",
                createAnother: "Create another user",
                isSupplier: "Are you creating a Supplier User?",
                supplier: "Supplier",
            },
            placeholders: {
                firstName: "First Name",
                lastName: "Last Name",
                emailAddress: "Email Address",
                phoneNumber: "Phone Number",
                address: "Address",
                company: "Company",
                role: "Role",
                field: "Search field..",
                operator: "Search operator..",
                searchValue: "Search company..",
                selectRole: "Select role..",
                searchSupplier: "Search supplier..",
            },
            optionLabels: {
                contains: "Contains (Full-text search)",
                equals: "Equals (=)",
                firstName: "First Name",
                lastName: "Last Name",
                email: "Email",
                company: "Company",
            },
        },
        buttons: {
            backToUsers: "Back to users",
            backToSubcontractors: "Back to subcontractors",
            create: "Create user",
            updateUser: "Update user",
            passwordResetLink: "Send password reset link",
            deleteUser: "Delete user",
            loading: "Loading...",
            viewSubcontractorVehicles: "View Sub-Contractor Vehicles",
            update: "Update",
        },
        titles: {
            newUser: "New user",
            manageUser: "Manage User",
            updateUser: "Update User",
        },
        modals: {
            headings: {
                createUserSuccess: "User successfully created!",
                updateUserSuccess: "User successfully updated!",
                subcontractorUpdated: "Subcontractor successfully updated!",
            },
        },
        toasts: {
            sendPasswordResetLinkSuccess: "Password reset link sent successfully",
            cannotUpdateUser: "User could not be updated. Try again",
        },
    },
    subcontractors: {
        createNew: "Create new",
        labels: {
            phone: "Phone",
            wc_license: "Waste Carriers Licence",
            email: "Email",
            postcode: "Postcode",
            username: "Username",
            password: "Password",
            company: "Company",
            status: "Status",
            onStop: "On Stop",
            enabled: "Enabled",
            disabled: "Disabled",
        },
        errors: {
            couldNotGenerateCoordinates: "Coordinates could not be generated for this postcode",
        },
        reviewVehicles: "Review Vehicles",
        review: "Review",
        approveVehicle: "Approve",
        rejectVehicle: "Disapprove",
        delete: "Delete",
        resetPassword: "Reset Password",
        manage: "Manage",
        edit: "Edit",
    },
    filters: {
        approveVehiclesHeading: "Search vehicles",
        approveVehiclesSearch: "Type here to search vehicles...",
        subcontractorJobsHeading: "Search jobs",
        subcontractorJobsSearch: "Type here to search jobs...",
    },
};
