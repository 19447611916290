"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.common = void 0;
exports.common = {
    loading: "Loading",
    tabs: {
        jobs: "Jobs",
        users: "Users",
        vehicles: "Vehicles",
    },
    form: {
        errors: {
            validPhone: "Phone number is not valid",
        },
    },
    googleMaps: "Google Maps",
    appleMaps: "Apple Maps",
    errors: {
        genericError: "An error occurred. Please try again.",
    },
};
