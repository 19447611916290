"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.en = void 0;
const deprecated_1 = require("./deprecated");
const portal_1 = require("./portal");
const global_1 = require("./global");
const common_1 = require("./common");
const dsa_1 = require("./dsa");
const hwa_1 = require("./hwa");
const website_1 = require("./website");
exports.en = {
    deprecated: deprecated_1.deprecated,
    portal: portal_1.portal,
    global: global_1.global,
    common: common_1.common,
    dsa: dsa_1.dsa,
    hwa: hwa_1.hwa,
    website: website_1.website,
};
