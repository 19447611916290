import { InputText } from "./InputText/InputText";
import { InputRadio } from "./InputRadio/InputRadio";
import { InputCheckbox } from "./InputCheckbox/InputCheckbox";
import { InputDate } from "./InputDate/InputDate";
import { InputRangedDate } from "./InputDate/InputRangedDate";
import { InputTextarea } from "./InputTextarea/InputTextarea";
import { InputToggle } from "./InputToggle/InputToggle";
import { InputSelect } from "./InputSelect/InputSelect";
import { InputFile } from "./InputFile/InputFile";

export const Input = {
  Text: InputText,
  Radio: InputRadio,
  Date: InputDate,
  RangedDate: InputRangedDate,
  Textarea: InputTextarea,
  Checkbox: InputCheckbox,
  Toggle: InputToggle,
  Select: InputSelect,
  File: InputFile,
};

export * from "./InputText/InputText";
export * from "./InputRadio/InputRadio";
export * from "./InputCheckbox/InputCheckbox";
export * from "./InputDate/InputDate";
export * from "./InputDate/InputRangedDate";
export * from "./InputTextarea/InputTextarea";
export * from "./InputToggle/InputToggle";
export * from "./InputSelect/InputSelect";
export * from "./InputFile/InputFile";
