import React from "react";
import { css } from "@emotion/react";
import { UserStylesProps } from "../../../../utils";
import { styles, AllowedColorKeys } from "./InputToggle.styles";
import { Text } from "../../../Text/Text";
export interface InputToggleProps
  extends UserStylesProps<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
  > {
  color?: AllowedColorKeys;
  label?: string;
}

export const InputToggle = ({
  color = "brand",
  label,
  styles: userStyles,
  ...rest
}: InputToggleProps): React.ReactElement => {
  return (
    <div css={css(userStyles)}>
      <Text as="label" css={css(styles.toggle)}>
        {label && (
          <Text as="span" fontSize="small" css={css(styles.label)}>
            {label}
          </Text>
        )}
        <input
          type="checkbox"
          role="switch"
          css={css(styles.input, styles.input.colors[color])}
          {...rest}
        />
        <Text
          as="span"
          className="toggle-slider"
          css={css(styles.toggleSlide)}
        />
      </Text>
    </div>
  );
};
