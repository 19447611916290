import { StyleSheet } from "../../utils";
import { theme } from "../../theme";

export const styles = StyleSheet({
  heading: {
    textAlign: "center",
    paddingLeft: theme.spacing.small,
    paddingRight: theme.spacing.small,
  },
  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  toggleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontFamily: "ProximaBold",
    marginTop: theme.spacing.small,
    marginBottom: theme.spacing.small,
  },
  toggleContainerLeft: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  chevron: {
    marginRight: theme.spacing.small,
  },
  clipboard: {
    padding: theme.spacing.xsmall2,
    borderRadius: theme.spacing.xsmall2,
    marginLeft: theme.spacing.small,
    cursor: "pointer",
    ":hover": {
      backgroundColor: theme.colors.light.base,
      color: theme.colors.brand.base,
    },
  },
  clipboardWrapper: {
    display: "flex",
  },
  errorStack: {
    marginTop: theme.spacing.small,
    marginBottom: theme.spacing.small,
    color: theme.colors.negative.base,
    fontSize: theme.fontDefaults.xsmall.fontSize,
    padding: theme.spacing.xsmall2,
  },
  buttonText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  retryIcon: {
    marginRight: theme.spacing.xsmall,
  },
  panel: {
    margin: "0 auto",
    backgroundColor: theme.colors.negative.base,
    color: theme.colors.light.base,
    maxWidth: theme.screens.medium,
    height: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing.small,
  },
});
