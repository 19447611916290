"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.modifiersToDatesInMonth = void 0;
var modifiersToDatesInMonth = function (modifiers, currentMonth) {
    var mods = Array.isArray(modifiers) ? modifiers : [modifiers];
    return mods.reduce(function (final, mod) {
        var dates = [];
        if (mod instanceof Date) {
            dates.push(mod);
        }
        if (mod.daysOfWeek) {
            dates.push.apply(dates, getDayOfWeekDates(currentMonth, mod));
        }
        else if (mod.before) {
            dates.push.apply(dates, getBeforeDates(currentMonth, mod));
        }
        else if (mod.after) {
            dates.push.apply(dates, getAfterDates(currentMonth, mod));
        }
        return __spreadArray(__spreadArray([], final), dates);
    }, []);
};
exports.modifiersToDatesInMonth = modifiersToDatesInMonth;
var getDayOfWeekDates = function (currentMonth, mod) {
    var dates = [];
    var i = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
    while (i.getMonth() === currentMonth.getMonth()) {
        if (mod.daysOfWeek.includes(i.getDay())) {
            dates.push(new Date(i));
        }
        i.setDate(i.getDate() + 1);
    }
    return dates;
};
var getBeforeDates = function (currentMonth, mod) {
    var count = 0;
    if (currentMonth.getMonth() === mod.before.getMonth() &&
        currentMonth.getFullYear() === mod.before.getFullYear()) {
        count = mod.before.getDate() - 1;
    }
    else if (currentMonth < mod.before) {
        count = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
    }
    return Array(count)
        .fill(undefined)
        .map(function (_, index) {
        return new Date(currentMonth.getFullYear(), currentMonth.getMonth(), index + 1);
    });
};
var getAfterDates = function (currentMonth, mod) {
    if (mod.after.getMonth() > currentMonth.getMonth()) {
        return [];
    }
    var startDay = mod.after.getMonth() < currentMonth.getMonth() ? 0 : mod.after.getDate();
    var monthTotalDays = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
    var count = monthTotalDays - startDay;
    return Array(count)
        .fill(undefined)
        .map(function (_, index) {
        return new Date(currentMonth.getFullYear(), currentMonth.getMonth(), monthTotalDays - index);
    })
        .sort(function (a, b) { return (a > b ? 1 : -1); });
};
