import React, { useState, useRef, useEffect } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { css } from "@emotion/react";
import DayPicker, { DayModifiers, DayPickerProps } from "react-day-picker";
import { UserStylesProps, CustomInputProps } from "../../../../utils";
import { styles } from "./InputDate.styles";
import { InputText } from "../InputText/InputText";
import { Icon } from "../../../Icon/Icon";
import {
  BaseInputDate,
  BaseProps,
  convertToDate,
  defaultDateFormat,
  formatDate,
  setNativeValue,
} from "./BaseInputDate";

dayjs.extend(customParseFormat);

export interface InputRangedDateProps
  extends CustomInputProps,
    UserStylesProps<
      React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >
    >,
    Pick<DayPickerProps, "disabledDays" | "month">,
    BaseProps {
  initialValue?: string[];
  dateFormat?: string;
  multiMonth?: boolean;
  collapsable?: boolean;
  acceptSingleDate?: boolean;
}

export const InputRangedDate = ({
  styles: userStyles,
  dateFormat = defaultDateFormat,
  disabledDays: customDisabledDays,
  initialValue = [],
  name,
  acceptSingleDate = false,
  multiMonth = false,
  collapsable = false,
  month,
  firstDay,
  ...rest
}: InputRangedDateProps): React.ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  const calendarRef = useRef<DayPicker>(null);
  const [days, setDays] = useState(initialValue);
  const [enteredTo, setEnteredTo] = useState<string>();
  const [showPicker, setShowPicker] = useState(!collapsable);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDays(event.target.value.split(" - "));
    rest.onChange?.(event);
  };

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    setShowPicker(!showPicker);
    rest.onClick?.(event);
  };

  const handleDayMouseEnter = (day: Date) => {
    setEnteredTo(days.length === 1 ? formatDate(day, dateFormat) : undefined);
  };

  const handleDayChange = (day: Date, modifiers: DayModifiers) => {
    if (!inputRef.current || modifiers["UI-DayPicker-Day--disabled"]) {
      return;
    }

    const newDays = days;
    const newDay = formatDate(day, dateFormat);

    if (
      newDays.length === 2 ||
      (newDays.length === 1 &&
        dayjs(newDay, dateFormat).isBefore(dayjs(newDays[0], dateFormat)))
    ) {
      newDays.splice(0, 2);
    }

    newDays.push(newDay);

    if (newDays.length === 2 && collapsable) {
      setShowPicker(false);
    }

    setNativeValue(inputRef.current, newDays.join(" - "));
    inputRef.current.dispatchEvent(new Event("input", { bubbles: true }));
  };

  const handleReset = () => {
    setDays([]);
    setEnteredTo(undefined);
  };

  const disabledDays = [
    ...(days.length === 1 && !acceptSingleDate
      ? [
          {
            before: convertToDate(days[0], dateFormat)!,
          },
        ]
      : []),
    ...(Array.isArray(customDisabledDays)
      ? customDisabledDays
      : [customDisabledDays]),
  ];

  useEffect(() => {
    if (!showPicker && collapsable && days.length === 1) {
      days.push(days[0]);
    }
  }, [showPicker]);

  return (
    <div
      data-testid="date-input-component"
      css={css(collapsable && styles.container, userStyles)}
    >
      {collapsable && (
        <>
          <Icon.Chevron
            color="brand"
            size="small"
            styles={css(styles.icon, showPicker && styles.up)}
          />
          <InputText
            {...rest}
            name={name}
            value={days[0] === days[1] ? days[0] ?? "" : days.join(" - ")}
            ref={inputRef}
            autoComplete="off"
            onChange={handleChange}
            onClick={handleClick}
            data-testid="date-input-field"
          />
        </>
      )}
      {!collapsable && (
        <InputText
          ref={inputRef}
          onChange={handleChange}
          style={{ display: "none" }}
        />
      )}

      <div
        css={[
          styles.dayPicker,
          !showPicker && styles.hideCalendar,
          !collapsable && styles.notCollapsable,
        ]}
      >
        <BaseInputDate
          multiMonth={multiMonth}
          dateRef={calendarRef}
          inputRef={inputRef}
          selectedDays={{
            from: convertToDate(days[0], dateFormat),
            to: convertToDate(
              days.length === 2 ? days[1] : enteredTo,
              dateFormat,
            ),
          }}
          modifiers={{
            start: convertToDate(days[0], dateFormat),
            end: convertToDate(enteredTo ?? days[1] ?? days[0], dateFormat),
          }}
          month={month ?? convertToDate(initialValue[0] ?? "", dateFormat)}
          disabledDays={disabledDays}
          onDayClick={handleDayChange}
          onOutsideClick={() => setShowPicker(!collapsable)}
          onReset={handleReset}
          onDayMouseEnter={handleDayMouseEnter}
          firstDay={firstDay}
        />
      </div>
    </div>
  );
};
